.secure-content-body {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100vh;
  padding-left: 3%;
  background-image: url(../assets/unlocked-image.png);
  background-repeat: no-repeat;
  background-position: center;
}
