body {
  background-color: var(--lightestbase);
}

/* Typography:  */
h5 {
  font-weight: 400;
  color: var(--darkestbase);
  font-size: 1.25rem;
  margin: 0;
}

p {
  font-weight: 200;
  margin: 0;
}

h6 {
  font-size: larger;
  margin: 0;
  padding: 0;
}

.text-link {
  text-decoration: underline;
  cursor: pointer;
  color: #000;
  font-weight: 200;
}

.bold {
  font-weight: 600;
}

.italic {
  font-style: italic;
}

.subhead {
  padding-bottom: 2rem;
  font-size: 1.3em;
}

.fake-link {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

/* Lists: */
ul {
  list-style-position: inside;
  line-height: 1.5rem;
  margin: 0;
  padding: 0;
  font-size: larger;
  color: var(--darkestbase);
}

.spacer {
  height: 5vw;
}

.app-hero-image {
  border: none;
  background-size: 38.5vw;
  position: absolute;
  right: 2vw;
  top: 3em;
  width: 38.5vw;
}

.header-body {
  color: var(--darkestbase);
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 25px;
  padding-bottom: 25px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  position: relative;
}

.header-image {
  width: 50%;
  height: auto;
  margin: 0 0 0 8rem;
}

.header-test-banner {
  position: absolute;
  height: 100%;
  top: 0;
}

.body-container {
  width: 100%;
  margin: 0 auto;
  padding-bottom: 3rem;
}

.body-container-head {
  margin: 2rem 0 0 7rem;
  padding: 0.5rem;
}

.body-container-head p {
  font-size: 1.5em;
}

.body-container-body {
  margin: 0 0 1rem 7rem;
  padding: 0.5rem;
  width: 55vw;
}

.body-container-body li {
  font-size: 19.2px;
}

.body-container-body.half-width {
  /* background: chartreuse; */
  margin: 0 0 1rem 7.25rem;
  width: 40vw;
}

.body-container-register {
  background-color: var(--lightMediumBase);
  padding: 0.75rem 0 0.25rem 8rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.body-container-content {
  display: flex;
}

.register-button {
  background-color: var(--accent1);
  height: 3rem;
  width: 15rem;
  border: none;
  margin: 0 0 0.5rem;
  color: var(--buttonText);
  cursor: pointer;
}

.register-button.deregister {
  background-color: var(--accent1);
  width: 20rem;
}

.register-addl-text {
  display: flex;
  flex-direction: column;
  margin: 0.35rem 0.35rem 0.35rem 2rem;
}

.register-button h4 {
  color: var(--darkestbase);
}

.all-instructions {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 2vw;
}

/* INDIVIDUAL SCREENS */

/* RegisterPFA: */
.body-container-get-started {
  background: var(--lightestBase);
  display: flex;
  flex-direction: column;
  margin: 0 0 1rem 7rem;
}

.body-container-get-started h1 {
  margin: 1rem 0 0.25rem 0;
}

.body-container-get-started h3 {
  margin: 0;
}

.all-instructions-container {
  margin: 1rem 0 0 0;
}

.one-instruction {
  display: flex;
  flex-direction: row;
  margin: 0 0 1rem 0;
  align-items: flex-start;
}

.one-instruction-img-container {
  padding: 0.25rem 1rem 0 0;
}

.instruction-content {
  width: 30rem;
  justify-content: center;
}

.apple-google-imgs {
  display: flex;
  flex-direction: row;
  margin: 1rem 0 1.5rem 0;
}

#apple-store-img {
  background-image: url(./assets/apple_store_download.png);
  background-size: 100%;
  height: 75px;
  width: 223px;
}

#google-play-img {
  background-image: url(./assets/google_play_download.png);
  background-size: 100%;
  height: 75px;
  width: 247px;
}

#user-status {
  margin-top: 0.1em;
  margin-bottom: 0.1em;
}

.device-requirements {
  font-size: 12px;
}

.qr-code-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 7%;
}

.qr-gen {
  border: var(--accent1) 0.5em solid;
}

.qr-code-refresh-instructions {
  display: flex;
  margin-top: 1rem;
  flex-direction: column;
  align-items: center;
}

.desktop-hidden {
  display: none;
}

.expired-qr {
  background: var(--lighterMediumBase);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.expired-qr-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.expired-qr-h2 {
  text-decoration: underline;
  cursor: pointer;
}

.expired-qr h2,
.expired-qr h1 {
  margin: 0 auto;
}

.scan-qr-title {
  font-size: 20px;
}

.help-contact-and-terms p {
  font-size: 12px;
}

.scan-qr-title img {
  margin: 0;
}

/* Challenge */

.auth-not-responded-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: var(--accent1);
  margin: 1rem 0 1rem 0;
}

.auth-not-responded-icon {
  padding: 0.75rem;
}

.challenge-container {
  width: calc(1200px - 50%);
  min-width: 800px;
  margin: 0 auto;
}

.challenge-container h5 {
  padding: 0.5rem 0 0.5rem 0;
}

.challenge-header {
  color: var(--darkestbase);
  background-image: url(./assets/header_background_texture.png);
  background-repeat: no-repeat;
  background-size: cover;
  height: 10rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
}

.challenge-header-image {
  width: 50%;
  height: auto;
  margin: 0 0 0 2rem;
}

.challenge-header-test-banner {
  z-index:1000;
  position: absolute;
  max-height: 100%;
}

.challenge-body-outer {
  background-color: var(--lightestMediumBase);
}

.challenge-body-inner {
  background-color: var(--lightestMediumBase);
  margin: 0 auto;
  width: 95%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 1rem;
}

.challenge-button {
  background-color: var(--accent1);
  color: var(--buttonText);
  padding: 0.35rem 1rem 0.25rem 1rem;
  margin: 1.5rem 0 0 1.5rem;
  text-align: center;
  text-decoration: none;
  height: 2rem;
  font-family: "Overpass";
  font-size: 1rem;
  border: none;
}

.challenge-button.return-to-login {
  margin: 0;
  margin-bottom: 1rem;
}

.entry-container {
  display: flex;
  flex-direction: row;
}

.entry-subcontainer {
  display: flex;
  flex-direction: row;
}

.challenge-label {
  display: block;
  box-sizing: border-box;
}

.challenge-input {
  width: 100%;
  height: 2rem;
  font-size: 1rem;
}

.challenge-button:hover {
  cursor: pointer;
}

.enter-code-and-cancel-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.5em;
}

/* Terms */
.terms-content {
  margin: 0 0 0 8rem;
  width: 50%;
}

#terms-back-button-image {
  transform: scaleX(-1);
}

.version-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 0 ;
}
.version-text {
  margin-top:5px;
}

/* FAQ */
.faq-content {
  margin: 0 0 0 8rem;
  width: 65%;
}

.question {
  margin-top: 50px;
}

.question-question {
  font-weight: 700;
  font-size: 32px;
}

.question-answer{
  margin-top: 20px;
  font-size: 18px;
  text-align: justify;
}

.question-image{
  width: 600px;
  margin-top: 10px;
}

/*Registered*/
.half-max-width {
  max-width: 45%;
}

#registered-top-container-content {
  width: 100%;
}

#registered-title-top {
  background-color: #008b20;
  color: var(--lightestBase);
  width: 100%;
  padding: 1em 0 2em 5em;
}

#registered-title {
  font-size: 32px;
  font-weight: 800;
}

#registered-subtitle {
  font-size: 26px;
  line-height: 32px;
}

#registered-title-middle {
  background-color: var(--accent3);
  width: 100%;
  padding: 1em 0 1em 5em;
}

#registered-title-bottom {
  background-color: var(--lightestMediumBase);
  width: 100%;
  padding: 1.5em 0 1.5em 5em;
}

/* PageNotFound: */
.not-found h1 {
  font-size: 5em;
  margin: 0.5em 0 0 0.1em;
}

.not-found p {
  font-size: 1.9em;
  margin: 0 0 0 0.3em;
}

/* Video Page */
.video-grid {
  display: grid;
  grid-template-columns: 256px 256px 256px 256px;
  gap: 30px;
  margin-bottom: 70px;
}

.videos-content {
  margin: 0 0 0 8rem;
  width: 50%;
}

.videos-content h6 {
  font-weight: 400;
}

.videos-content h4 {
  margin-bottom: 20px;
}

#videos-back-button-image {
  transform: scaleX(-1);
}

.videos-subtitle {
  font-size: 26px;
}

.videos-title-grid {
  margin-left: 50px;
}

.videos-back {
  font-weight: 600;
}

/* Singular Video Page */
.video-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.video-title {
  font-size: 30px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.video-description {
  margin-top: 10px;
}

/* Media Queries: */

/* Tablet: */
@media (max-width: 1200px) {
  h4,
  p,
  li {
    font-size: 1rem;
  }

  .body-container-body li {
    font-size: 1rem;
  }

  .header-image {
    width: 50%;
    margin: 0 0 0 3rem;
  }

  .spacer {
    display: none;
  }

  .body-container-register {
    align-items: flex-start;
    padding: 0.75rem 0 0.25rem 2rem;
  }

  .one-instruction-img-container img {
    width: 70%;
    height: 70%;
    min-width: 33.5px;
    min-height: 33.5px;
  }

  .body-container-head {
    width: 85%;
    margin: 1rem 0 0 1.5rem;
  }

  .body-container-body {
    width: 55%;
    margin: 0 0 1rem 1.5rem;
  }

  .body-container-get-started {
    width: 85%;
    margin-left: 5px;
  }

  #bullet-3 {
    display: none;
  }

  .videos-thumbnail {
    width: 256px;
    height: 144px;
  }

  .video-grid {
    gap: 20px;
    grid-template-columns: 256px 256px;
  }

  .video-title {
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 5px;
  }

  .desktop-hidden {
    display: initial;
    margin-left: unset;
  }
}

/* Phone */
@media (max-width: 700px) {
  .header-body {
    height: 5rem;
    width: 100%;
  }

  .body-container {
    width: 100%;
  }

  .header-image {
    width: 75%;
    margin: 0 auto;
  }

  .mobile-hidden {
    display: none;
  }

  video {
    width: 90%;
  }

  .videos-thumbnail {
    width: 128px;
    height: 72px;
  }

  .video-grid {
    gap: 5px;
    grid-template-columns: 128px 128px;
  }

  .video-title {
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 5px;
  }

  .desktop-hidden {
    display: initial;
    margin-left: unset;
  }

  .body-container-body.half-width {
    margin: 0;
    width: 100%;
  }

  #bullet-3 {
    display: none;
  }

  .app-hero-image {
    display: none;
  }

  .body-container-challenge {
    padding: 0.5rem 0 0.5rem 2rem;
  }

  .body-container-register {
    padding: 0.75rem 0 0.75rem 0;
    align-items: center;
    flex-direction: column;
  }

  .body-container-head p {
    font-size: 1.3em;
  }

  .register-addl-text {
    margin: 0;
    margin-top: 0.5rem;
    align-items: center;
  }

  .register-button.deregister {
    width: 20rem;
  }

  .subhead {
    padding-bottom: 2rem;
  }

  .apple-google-imgs {
    flex-direction: column;
  }

  .one-instruction {
    flex-direction: row;
  }

  .instruction-text {
    align-self: flex-end;
  }

  .instruction-content {
    width: 100%;
  }

  .scan-qr-title {
    display: none;
  }

  .terms-content {
    margin: 0 0 0 2rem;
    width: 85%;
  }

  .faq-content {
    margin: 0 0 0 2rem;
    width: 85%;
  }

  .videos-content {
    margin: 0 0 0 2rem;
    width: 85%;
  }

  .challenge-header-image {
    width: 80%;
  }

  .challenge-container {
    width: 100%;
    margin: 0 auto;
    min-width: unset;
  }

  .enter-code-and-cancel-container {
    flex-direction: column;
  }

  .auth-not-responded-icon {
    padding: 2rem 1rem 0 1rem;
  }

  #registered-title-bottom {
    padding-left: unset;
    text-align: center;
  }

  #registered-title-bottom p {
    padding: 0.8rem;
  }

  #registered-title-top {
    padding: 0.5rem;
    text-align: center;
    width: unset;
  }

  #registered-title-middle {
    width: unset;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    text-align: center;
  }

  .half-max-width {
    max-width: unset;
  }

  .challenge-body-inner {
    width: 90%;
  }
}
