.login-qr-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin: 2rem 0;
}

.qr-loading-container {  
  display:flex;
  justify-content:center;
  align-items:center;
  background:#ddd
}

.qr-loader-styling {
  border-color: #adadad;
  border-top-color: #000
}

#login-qr-container-instructions {
  font-size: 1.2em;
  margin-bottom: 1rem;
}

.login-button-qr{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login-button-qr div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login-qr-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
