.login {
  font-family: Helvetica, Arial, sans-serif;
}

.login {
  font-size: 75%;
  color: #ffffff;
  background: #f6f6f6;
  margin: 0;
}

.loginLink {
  display: flex;
  color: #ffffff;
  text-decoration: none;
  outline: none !important;
  font-size: 1.5em;
  margin-top: 20px;
}

.loginLink:hover,
.link:hover h4 {
  color: #fec325;
  text-decoration: underline;
}

.loginLink img {
  border: none;
}

#wrap {
  background-color: #000000;
  width: 100%;
  min-height: 900px;
  min-width: 900px;
  position: relative;
}

form p,
.login-support {
  width: 351px;
  padding-left: 10px;
}

.login-support,
#login_form p,
#login_form div {
  background: none;
  padding: 0;
  width: 371px;
}

#content {
  position: absolute;
  right: 0;
  top: 0;
  background: #000000;
  width: 50%;
  height: 95%;
  padding-top: 24px;
  padding-bottom: 24px;
}

#content h2 {
  font-size: 3.08em;
  font-weight: normal;
  margin: 13% 0 20px 10%;
}

.login-error {
  margin: 0% 0 20px 10%;
  color: #dc143c;
}

#login_form h4 {
  font-size: 1em;
  font-weight: bold;
}

.login-fieldset {
  margin: 0;
  padding: 0;
  border: 0 none;
  margin-left: 10%;
}

.login-fieldset input {
  width: 98%;
  height: 40px;
  line-height: 26px;
  margin: 5px 0px 14px -2px;
  padding-left: 6px;
  border: 2px solid #d3d3d3;
  font-size: 1.33em;
}

.login-fieldset label {
  font-weight: bold;
  margin: 0 5px 0 0;
  padding: 5px 0 0 0;
}

.login input.valid,
.login input.invalid {
  border-width: 2px;
}

.login legend {
  display: block;
  position: absolute;
  top: 0;
  left: -500em;
  width: 1px;
  height: 1px;
  overflow: hidden;
  text-indent: -9999em;
  line-height: 0;
}

#login-buttons {
  /* margin: 10px auto 0; */
  /* text-align: center; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0;
  margin-left: 10%;
}

.akam-button,
.akam-cancel {
  border: 0 none;
  font-weight: bold;
  font-size: 14px;
  padding: 6px;
  width: 100px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px 2px 2px 2px;
}

.login-support {
  display: flex;
  justify-content: space-between;
  padding-top: 6px;
  margin-top: 32px;
  margin-left: 10%;
}

.login-support p {
  line-height: 130%;
  margin-right: 12%;
}

.login-support b {
  font-size: 120%;
}

.ui-dialog .ui-dialog-titlebar-close span {
  margin: -8px 0 0 -9px;
}

#background-image {
  position: relative;
  overflow: hidden;
  width: 100%;
  margin-left: -20%;
  height: 900px;
}

#background-inner-image1 {
  position: absolute;
  object-fit: cover;
  left: -1.5%;
  height: 100%;
  opacity: 1;
  transition: opacity 0.4s ease-in-out;
}

#background-inner-image2 {
  position: absolute;
  object-fit: cover;
  right: 10%;
  height: 100%;
  opacity: 1;
  transition: opacity 0.4s ease-in-out;
}

#logo-image-container {
  position: absolute;
  left: 25%;
  top: 40%;
}

#logo-image {
  margin-left: -50%;
  width: 100%;
}

#login {
  background-color: #fec325;
  width: 42%;
  height: 40px;
}

#login-choices {
  padding: 0;
  margin-left: 10%;
}

#pki-login {
  background-color: #beb8a8;
  width: 42%;
  height: 40px;
}

.login-support-footnote {
  font-size: 0.8em;
  margin-top: 40px;
  color: #808080;
}

#aesd {
  margin-top: 12px;
  text-align: right;
}

#aesd-logo {
  height: 90px;
  width: 138px;
}

/* desktop */
@media only screen and (min-width: 1800px) {
  #background-inner-image1 {
    right: 0;
    left: 0;
  }

  #background-inner-image2 {
    right: 0;
    left: 0;
  }
}

/* phone */
@media only screen and (max-width: 899px) {
  #wrap {
    min-width: 100%;
  }

  #content {
    position: unset;
    width: 100%;
    padding: unset;
    padding-bottom: 24px;
  }

  #background-image {
    height: 216px;
    margin-left: unset;
  }

  #background-inner-image1 {
    right: 0;
    left: 0;
    width: 100%;
  }

  #background-inner-image2 {
    right: 0;
    left: 0;
    width: 100%;
  }

  #logo-image-container {
    top: 10%;
    height: auto;
    left: 50%;
  }

  #content h2 {
    font-size: 1.5em;
    width: 80%;
    margin-left: 6%;
  }

  #mobile-newline {
    display: inline-block;
  }

  .login fieldset input {
    /* width: 99%; */
    width: 85%;
    height: 40px;
    line-height: 26px;
    margin-left: -2px;
    padding-left: 1%;
    border: 2px solid #d3d3d3;
    font-size: 1.33em;
  }

  .box,
  .login-support,
  #login-buttons,
  .login fieldset {
    width: 94%;
    margin-left: 3%;
    margin-right: 3%;
  }

  .login form p,
  .login form div {
    width: 94%;
    margin-left: 3%;
    margin-right: 3%;
  }

  #login-buttons {
    justify-content: space-between;
    /* width: 88%; */
    width: 50%;
    /* margin-left: 6%; */
  }

  .login-support p {
    margin-right: 3%;
    margin-left: 3%;
  }

  #aesd {
    margin-top: 12px;
    margin-right: 2%;
    text-align: right;
  }

  #aesd-logo {
    width: 138px;
    height: 90px;
  }

  .alert {
    padding-left: 5%;
  }
}

/* Airforce */
/**
* CSS for the login page.
*/

/**
* 1. Set default font family to sans-serif.
* 2. Prevent iOS text size adjust after orientation change, without disabling
*    user zoom.
* Montserrat - Google Font
* https://fonts.google.com/specimen/Montserrat?selection.family=Montserrat:400,500,600,700
* Regular 400
* Medium 500
* Semi-Bold 600
* Bold 700
*/
.af-body {
  font-family: "Montserrat", sans-serif; /* 1 */
  -ms-text-size-adjust: 100%; /* 2 */
  -webkit-text-size-adjust: 100%; /* 2 */
  font-display: fallback;
}

.af-body h2 {
  color: #666;
  font-size: 20px;
  font-weight: 500;
}

.af-body h3 {
  color: #666;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
}

.af-body h4 {
  color: #666;
  font-weight: 700;
  font-size: 12px;
}

/**
* Begin login page styles.
*/
#globalNav {
  position: relative;
  background-color: #112e51;
  border-bottom: 1px solid #d1d3d4;
  border-bottom: 1px solid transparent; /* IE8 */
  box-shadow: 0 1px 0 0px #f7f8f8;
  height: 90px;
  width: 100%;
}

#globalNavLogo {
  position: relative;
  top: 7px;
  left: 17px;
}

#mainContent {
  margin: 0 2rem;
  max-width: 1280px;
}

#af-login-buttons {
  /* margin: 10px auto 0; */
  /* text-align: center; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0;
  margin-left: 10%;
}

.titleSection {
  position: relative;
  text-align: center;
}

.loginTitle {
  color: #1a0c28;
  margin: 35px 0 0 0;
  font-size: 48px;
  font-weight: 400;
}

.cceImage {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
}

/* Tab Navigator Styles */
.header {
  border-top: 1px solid #979797;
  height: 20px;
  position: relative;
}

ul.tabnav {
  padding-left: 0;
  height: 25px;
  position: absolute;
  top: -53px;
}

ul.tabnav li {
  background-color: #fff;
  border: 1px solid #979797;
  border-bottom: none;
  color: #000000;
  float: left;
  font-size: 12px;
  height: 25px;
  padding-top: 11px;
  width: 115px;
  text-align: center;
  list-style: none;
  margin-right: 10px;
}
ul.tabnav li:hover {
  cursor: pointer;
}

ul.tabnav li.inactive {
  background-color: #c8cdd3;
  color: #24316c;
  border-bottom: 1px solid #979797;
  padding-top: 10px;
}

/* End Tab Navigator Styles */

.contentSection {
  margin: 0 0 25px 0;
  display: inline-block;
  width: 100%;

  text-align: center;
}

.loginSection {
  float: left;
  width: 28%;
  margin-top: 1.75rem;
  min-width: 50px;
}

.loginSection h4 {
  margin: 0;
}

.loginSection p {
  color: #1a0c28;
  font-size: 0.7rem;
  font-weight: 400;
  line-height: 1.5em;
  margin-top: 2em;
}

.cacImage {
  display: block;
  margin: auto;
  width: 6rem;
}

.cac-login-wrapper {
  position: relative;
  margin: 0 auto;
  max-width: 750px;
  padding-bottom: 40px;
}

.cceBlackImage {
  float: left;
  padding: 0.6rem 0.5rem 0 1rem;
  width: 5rem;
}

.btn-primary {
  background-color: rgb(36, 49, 108);
  font-size: 1.1rem;
  width: 12rem;
  height: 3.5rem;
  padding: 0px 0px 0px 0px;
  transition: background 0s;
  color: rgb(255, 255, 255);
  cursor: pointer;
  margin-bottom: 8px;

  -ms-user-select: none;
  -moz-transition: background 0s;
  -webkit-transition: background 0s;
  -o-transition: background 0s;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;

  border: none;
  border-style: solid;
  border-width: 0px;

  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}

.supportSection {
  margin-top: 30px;
  text-align: center;
  margin-left: 1rem;
}

.supportSection h3 {
  color: #666;
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 2rem;
}

.supportSection ul {
  list-style: none;
  padding-left: 0;
}

.supportSection ul li {
  color: #7099ed;
  margin-bottom: 1.2em;
}

.supportSection ul li a {
  font-weight: 500;
}

a {
  color: #2f4cb3;
}

a:hover {
  color: #24316c;
}

.linkInline {
  text-decoration: none;
}

.infoinput {
  float: left;
  line-height: 25px;
}

.af-body footer {
  clear: both;
  background-color: #f1f9ff;
  color: #251833;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5em;
  padding: 16px 0 38px 0;
  text-align: center;
}

.footerSection {
  margin: 38px 8rem 2em 8rem;
  text-align: left;
  max-width: 1280px;
}

.af-body footer ul {
  padding-left: 2.5em;
}

.af-body footer ul li {
  margin-bottom: 1em;
}
