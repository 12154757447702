.hide {
  display: none;
}

#bodyContent {
  padding: 30px;
  padding-top: 10px;
  background-color: var(--lightestBase);
}

.subBodyContent {
  display: flex;
  margin-top: 20px;
  justify-content: space-evenly;
}

.subHeader {
  color: var(--darkMediumBase);
}

.deregButton {
  display: flex;
  background-color: var(--accent1);
  color: var(--buttonText);
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 150px;
  height: 50px;
  margin-left: 30px;
}

#optionAction {
  display: flex;
  flex-direction: row;
  margin-left: 35px;
  margin-bottom: 10px;
}

#dot {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  border-color: var(--darkestBase);
  margin-right: 20px;
}

#textInput {
  background-color: var(--lightestBase);
  padding: 10px;
  border-color: transparent;
  margin-left: 75px;
  width: 60%;
  margin-bottom: 30px;
}

#cancelButton {
  display: flex;
  background-color: var(--lightestBase);
  border: 2px solid var(--darkestBase);
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 150px;
  height: 50px;
  margin-left: 30px;
}

#searchContainer {
  display: flex;
  background-color: var(--darkestBase);
  justify-content: center;
  margin-bottom: 10px;
  flex-direction: row;
  align-items: center;
}

#modalContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: var(--darkestBase);
  position: absolute;
  top: 0px;
  left: 0px;
}

#bannerImage {
  display: flex;
  width: 100%;
}

.flex {
  flex: 0.33;
}

#searchLabel {
  color: var(--buttonText);
  margin-right: 20px;
  font-size: 24px;
}

.iconImages {
  margin: 10px;
  width: 25px;
  height: 25px;
}

.marginT {
  margin-top: 50px;
}

.marginL {
  margin-left: 35px;
}

.marginB {
  margin-bottom: 10px;
}

#modal {
  background-color: var(--lightestBase);
  width: 500px;
  height: 450px;
}

#buttonContainer{ 
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
