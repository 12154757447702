.accordianPanel {
    background-color: #f8f9fa;
    width: 75vw;
    margin-top: 20px;
}

.panel {
    border: 1px solid rgba(0,0,0,0.125);
}

.namePlus {
    font-size: 20px;
    padding: 20px;
    border: 1px solid rgba(0,0,0,0.125);
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    border-collapse: collapse;
}

.certImages {
    width: 75vw;
    display: flex;
    margin-bottom: 20px;
}

.certImages img {
    width: 20%;
    min-width: 200px;
    margin-right: 20px;
    border: 1px solid rgba(0,0,0,0.125);
}

.certList li {
    margin-top: 30px;
    margin-bottom: 10px;
}

.accordianPanel :first-child {
    border-bottom: none;
}

.panelPlus{
    font-size: 30;
    font-weight: bold;
    border-collapse: collapse;
}

.panelContent {
    padding: 20px;
    border-top:  1px solid rgba(0,0,0,0.125);
    background-color: white;
    border-collapse: collapse;
    list-style-position: inside;
}

.androidCertImage{
    width: 20%;
    border: 1px solid rgba(0,0,0,0.125);
}

@media only screen and (max-width: 899px) {
    .androidCertImage {
        width: 50%;
    }
}