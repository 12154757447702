.qr-code-container-pfa {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 7%;
}

.mobile-link-container {
  display: none;
}

@media (max-width: 700px) {
  .qr-code-container-pfa {
    display: none;
  }

  .mobile-link-container {
    display: flex;
  }

  .mobile-link-container button {
    background-color: var(--accent1);
    height: 3rem;
    width: 15rem;
    border: none;
    color: var(--buttonText);
  }

  .mobile-link-container span {
    font-size: 1rem;
    font-weight: 600;
    color: var(--buttonText);
  }
}
