.arrow-back-button {
  display:inline-block;
  cursor: pointer;
  margin-top: 20px;
}

.arrow-back-button img {
  width: 35px;
  height: 35px;
}
