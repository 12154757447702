.loaderContainer {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  /* height: 40vh; */
  justify-content: center;
}

.loader {
  border: 10px solid var(--accent2);
  border-top: 10px solid var(--accent1);
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
  align-self: center;
}
